import styled from "styled-components";

export default styled.div.attrs({ className: "password-textbox" })`
  width: 100%;

  .MuiInputBase-root {
    border: 2px solid var(--gray-outline-color);
    border-radius: 45px !important;
    background-color: #fff !important;

    & input,
    textarea {
      border: none;
    }

    .start-adornment {
      width: 42px;
    }

    .MuiInputAdornment-root {
      margin: 5px 10px 5px 0px;
    }
  }

  .Mui-error {
    border-color: var(--secondary-color) !important;
    opacity: 1;
  }
`;
