import React from "react";
import { TextField } from "@mui/material";
import MaskedInput from "react-text-mask";

import TextboxStyles from "./TextboxStyles";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
};

export default function Textbox({
  onClick = () => {},
  onChange = () => {},
  onBlur = () => {},
  name,
  value,
  type = "text",
  placeholder,
  label = "",
  isMultiline = false,
  rows = 3,
  error = false,
  errorText = "",
  required = false,
  variant = null,
  ...props
}) {
  const maxLength = props.maxLength
    ? props.maxLength
    : isMultiline
    ? 1000
    : type === "password"
    ? 30
    : 120;

  const handleKeyPress = (event) => {
    if (props.onEnter) {
      if (event.key === "Enter") {
        props.onEnter(event);
      }
    }

    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };

  const handleNumberInput = (e) => {
    const numericValue = parseFloat(e.target.value);
    const maxValue = props.maxValue ?? 1000000000;

    // Check if the numeric value exceeds the max value (1000000000)
    if (!isNaN(numericValue) && numericValue > maxValue) {
      e.target.value = maxValue;
    }

    // Check if the numeric value is below the min value (0)
    if (!isNaN(numericValue) && numericValue < 0) {
      e.target.value = "0";
    }
  };

  return (
    <TextboxStyles>
      <TextField
        name={name}
        id={name}
        type={type}
        value={value}
        variant="outlined"
        placeholder={placeholder ? placeholder : label}
        onClick={(e) => onClick(e)}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onKeyPress={(e) => handleKeyPress(e)}
        onInput={(e) => (type === "number" ? handleNumberInput(e) : () => {})}
        multiline={isMultiline}
        rows={isMultiline ? rows : 0}
        error={error}
        helperText={error && errorText ? errorText : ""}
        inputProps={{
          maxLength,
        }}
        InputProps={{
          ...(variant === "telephone"
            ? {
                inputComponent: TextMaskCustom,
              }
            : {}),
        }}
        {...props}
      />
    </TextboxStyles>
  );
}
