export const getActiveConversation = (state) => {
  return state?.activeConversation;
};
export const getConversations = (state) => state.chat.conversations;
export const getConversationByConversationID = (state, conversation_id) => {
  if (Object.keys(state.conversations).length === 0) {
    return null;
  }

  return (
    state.conversations[
      conversation_id ? conversation_id : state.activeConversation
    ] || null
  );
};
export const getChatPreviewByConversationId = (state, conversationId) => {
  let id = conversationId ?? state.activeConversation;
  const _foundChat = state.chats?.find((chat) => chat.conversation_id === id);
  const { user_deleted = false, user_blocked = false } =
    state?.conversations[state?.activeConversation]?.user_info || {};
  return { ..._foundChat, user_deleted, user_blocked };
};

export const getLinkPreview = (state, key) => {
  return state.linkPreviews[key];
};

export const getChatContactListItem = (state, id, items) => {
  if (!state?.contacts) return;

  if (items) {
    return items.find((contact) => contact.id === id) || null;
  }
  return state.contacts.find((contact) => contact.id === id) || null;
};

/**
 * Calculate total unread message count without API i.e using active chats data
 * @param {*} items
 * @returns
 */
export const calculateTotalUnreadCountWithoutAPI = (state, items) => {
  function evaluateCount(chats) {
    let result = chats.reduce(function (acc, obj) {
      return acc + obj.count;
    }, 0);
    return result;
  }

  if (items?.length === 0) return 0;
  if (items) {
    return evaluateCount(items);
  }

  if (!state?.chats || state?.chats?.length === 0) return 0;
  return evaluateCount(state.chats);
};

export const isSending = (state) => state.messageSending;

export const filterItems = (state) => state.networkFilters;
export const chatStoreState = (state) => state.chatStore;
