import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActionCable from "actioncable";
import { clone } from "lodash";

import { commonApiFN } from "../common/api";
import { FetchAllNotificationCount } from "../common/module_wise_api_url/notifications";
import config from "../config";

const SOCKET_URL = config().SOCKET_URL;

/**
 * This provider component connects with webSocket connection and subscribes to different available channels
 * References to subscribed channels are stored in redux store and made available to app
 * @returns null
 */
export default function WebSocketProvider() {
  const { user, isLogin, isSuperAdmin } = useSelector(
    (state) => state.loginReducer
  );
  const dispatch = useDispatch();

  const chatChannel = useRef(null);
  const notificationChannel = useRef(null);
  const reactionsChannel = useRef(null);

  useEffect(() => {
    if (!isLogin) return;

    console.log("Websocket provider executed");

    // Create websocket connection
    const cable = ActionCable.createConsumer(`${SOCKET_URL}${user.id}`);

    // Channel subscription - Chat
    chatChannel.current = cable.subscriptions.create(
      { channel: "ConversationChannel" },
      {
        connected: () => {
          console.log("WebSocket: connected to ConversationChannel");
        },
        received: (data) => {
          console.log("WebSocket broadcast: ", data);
          // Update state with broadcast data
          dispatch({
            type: "setBroadcastChat",
            payload: { data: data },
          });
        },
        create: function (chatContent) {
          console.log("SPEAK method payload:", chatContent);
          this.perform("speak", {
            content: chatContent,
          });
          dispatch({ type: "broadcastSent" });
        },
      }
    );

    // Update store with socket channel reference
    // dispatch(setConversationChannel(chatChannel))
    dispatch({
      type: "SET_CONVERSATION_CHANNEL",
      payload: chatChannel,
    });

    // Channel subscription - Reactions
    reactionsChannel.current = cable.subscriptions.create(
      {
        channel: "ReactionChannel",
      },
      {
        connected: () => {
          dispatch({
            type: "setSocketReactionsConnectionStatus",
            payload: true,
          });
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Connected"
          );
        },
        received: (data) => {
          console.log(
            "MSG Socket Conversation Reactions Channel - Broadcast: ",
            data
          );
          dispatch({ type: "processWebsocketReactionsMessage", payload: data });
        },
        message: function (reactionContent) {
          this.perform("receive", reactionContent);
          console.log(
            "MSG Socket Conversation Reactions Channel - Message Payload: ",
            reactionContent
          );
        },
        disconnected: () => {
          dispatch({
            type: "setSocketReactionsConnectionStatus",
            payload: true,
          });
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Disconnected"
          );
        },
      }
    );

    // Update store with socket channel reference
    dispatch({
      type: "SET_REACTIONS_CHANNEL",
      payload: reactionsChannel,
    });

    /** Notification - START */
    // Notifications are not required for super admin
    if (!isSuperAdmin) {
      // Call All Notification Count API
      (async () => {
        try {
          const request = clone(FetchAllNotificationCount);
          const response = await commonApiFN(request);

          if (response.success && response.data) {
            dispatch({
              type: "SET_ALL_NOTIFICATION_COUNTS",
              payload: response.data,
            });
          }
        } catch (error) {
          console.error(error);
        }
      })();

      // Channel subscription
      notificationChannel.current = cable.subscriptions.create(
        { channel: "NotificationChannel" },
        {
          connected: () => {
            console.log("WebSocket: connected to NotificationChannel");
          },
          received: (data) => {
            console.log(
              "WebSocket: received on NotificationChannel -> ",
              JSON.stringify(data)
            );
            if (data) {
              if (data.notification_type === "topic")
                dispatch({
                  type: "SET_LIVE_TOPIC_NOTIFICATIONS",
                  payload: data,
                });
              else if (data.notification_type === "chat")
                dispatch({
                  type: "SET_LIVE_CHAT_NOTIFICATIONS",
                  payload: data,
                });
            }
          },
          disconnected: () => {
            console.log("WebSocket: disconnected from NotificationChannel");
          },
        }
      );

      // Update store with socket channel reference
      dispatch({
        type: "SET_NOTIFICATION_CHANNEL",
        payload: notificationChannel,
      });
    }
    /** Notification - END */

    return () => {
      // Disconnect websocket connections
      // Reset websocket state
      dispatch({
        type: "RESET_ALL_CHANNELS",
      });
      dispatch({
        type: "setSocketReactionsConnectionStatus",
        payload: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, user.id, dispatch]);

  return null;
}
