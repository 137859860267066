const config = () => {
  const commonConfiguration = {
    GOOGLE_CLIENT_ID:
      "662715351295-41idhil6qd6qbqib1ll3dk5san21d9a8.apps.googleusercontent.com",
    CALENDLY_CLIENT_ID: "tedOXhEk9_LZtaLqDrLizgh8dxVDIKGI5INjOLMU4xE",
    OUTLOOK_CLIENT_ID: "53fefc4b-f059-4701-b377-0eb6091dabc0",
    ZOOM_CLIENT_ID: "8XldQM5eSQaNOa0QKPX7qw", //production Dhaval
    MSCLARITY_ID: "iez1czv09l",
    GOOGLE_TAG_MANAGER_ID: "GTM-MKZDSCV",
    TINY_MCE_API_ID: "qw9zwfzk9lk0vxsfk2c11hh7dto8etqd25tq951bhzkx6z0r",
  };
  if (process.env.REACT_APP_ENV === "prod") {
    return {
      // API_URL: "https://web.interstride.com/api/v1/",
      API_URL: "https://web.production.interstride.com/api/v1/",
      SOCKET_URL: "wss://cable.interstride.com/cable?token=",
      BUGSNAG_API_KEY: "26fde5bde2703cfd2ee05d3deae02979",
      GOOGLE_SERVICE_API_KEY: "AIzaSyCal6I_mnG7mg9yWA4gWRwYogj-OEbWoXs",
      ...commonConfiguration,
    };
  } else if (process.env.REACT_APP_ENV === "stage") {
    return {
      API_URL: "https://web.testing.interstride.com/api/v1/",
      SOCKET_URL: "wss://web.cable.testing2.interstride.com/cable?token=",
      BUGSNAG_API_KEY: "001680d56a162d1185e5e0548503cf7c",
      ...commonConfiguration,
      GOOGLE_SERVICE_API_KEY: "AIzaSyDStqZYYGixLPFVNaUdlBijidr_DnhjKpg",
    };
  }
  // Pre-production for admissions
  else if (process.env.REACT_APP_ENV === "pre-prod") {
    return {
      API_URL: "https://web.staging.interstride.com/api/v1/",
      SOCKET_URL: "wss://staging.cable.interstride.com/cable?token=",
      BUGSNAG_API_KEY: "001680d56a162d1185e5e0548503cf7c",
      ...commonConfiguration,
      GOOGLE_SERVICE_API_KEY: "AIzaSyDStqZYYGixLPFVNaUdlBijidr_DnhjKpg",
    };
  } else {
    // process.env.REACT_APP_ENV === "dev"
    return {
      API_URL: "https://web.testing.interstride.com/api/v1/",
      SOCKET_URL: "wss://web.cable.testing2.interstride.com/cable?token=",
      BUGSNAG_API_KEY: "001680d56a162d1185e5e0548503cf7c",
      GOOGLE_SERVICE_API_KEY: "AIzaSyDStqZYYGixLPFVNaUdlBijidr_DnhjKpg",
      ...commonConfiguration,
    };
  }
};

export default config;
