let initState = {
  conversationChannel: null,
  notificationChannel: null,
  conversationReactionsChannel: null,
};

const webSocketReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CONVERSATION_CHANNEL":
      return {
        ...state,
        conversationChannel: action.payload,
      };
    case "SET_NOTIFICATION_CHANNEL":
      return {
        ...state,
        notificationChannel: action.payload,
      };
    case "SET_REACTIONS_CHANNEL": {
      return {
        ...state,
        conversationReactionsChannel: action.payload,
      };
    }
    case "RESET_ALL_CHANNELS":
      return {
        ...initState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default webSocketReducer;
